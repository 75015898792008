import React, { useCallback, useContext, useMemo } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowUp from "@material-ui/icons/KeyboardArrowUp";
import ArrowDown from "@material-ui/icons/KeyboardArrowDown";
import Toolbar from "@material-ui/core/Toolbar";
import { UserTypeContext } from "../../../UserTypeContext";
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import clsx from "clsx";
import enumStrings, { RedirectUri } from "../../enumStrings";
import {
  ShopLocalCampaignStatusEnum,
  useGetActiveShopLocalCampaignForChamberMemberQuery,
  useGetAllShopLocalCampaignsForChamberQuery,
  useGetAllRewardCampaignsForChamberQuery,
  UserType,
} from "../../../graphql/types";
import { Link } from "react-router-dom";
import themes from "../../../themes";
import LogoImage from "../utilities/LogoImage";
import useGetChamberFromUserIdInAuth from "../Hooks/useGetChamberFromUserIdInAuth";
import CPALogo from "../../../images/Gold_Logo.png";
import useGetChamberMemberFromUserIdInAuth from "../Hooks/useGetChamberMemberFromUserIdInAuth";
import CustomStatsIcon from "../../../images/CustomStatsIcon.png";
import CustomManageChamberProfile from "../../../images/CustomManageChamberProfile.png";
import CustomManageCompanyProfile from "../../../images/CustomManageCompanyProfile.png";
import CustomManageCompanyPerks from "../../../images/CustomManageCompanyPerks.png";
import CustomManageMembersPerks from "../../../images/CustomManageMembersPerks.png";
import CustomViewMemberPerks from "../../../images/CustomViewMemberPerks.png";
import CustomAddIcon from "../../../images/CustomAddIcon.png";
import CustomSupport from "../../../images/CustomSupport.png";
import UserGuideAndSupportIcon from "../../../images/UserGuideAndSupportIcon.png";
import ShopLocalIcon from "../../../images/ShopLocalIcon.png";
import RewardIcon from "../../../images/RewardIcon.png";
import PerkLinkIcon from "../../../images/PerkLinkIcon.png";
import CustomLogoutIcon from "../../../images/CustomLogoutIcon.png";
import CPAMarketingToolkitLogo from "../../../images/CPAMarketingToolkitLogo.png";
import HandWithPersonIconLogo from "../../../images/HandWithPersonIconLogo.png";
import { ListItemButton } from "@mui/material";
import useIsChamber from "../Hooks/useIsChamber";
import { useHandleLogout } from "../Hooks/useHandleLogout";
import { Chip, Tooltip, Typography } from "@material-ui/core";
import CustomChamberEngageIcon from "../../../images/CustomChamberEngageIcon.png";
import CustomChamberMarketerLogo from "../../../images/CustomChamberMarketerLogo.png";
import CustomManualLaunchGuideLogo from "../../../images/CustomManualLaunchGuideLogo.png";
import useIsMemberProfileAndPerksBannersActiveForChamberMember from "../Hooks/useCheckIfMemberProfileAndPerksBannersActiveForChamberMember";
import ChamberEngageManageCategoryAdvertisingBannersChamberMemberIcon from "../../../images/ChamberEngageManageCategoryAdvertisingBannersChamberMemberIcon.png";
import { checkIfRewardCampaignIsActive } from "../utilities/rewardCampaign";

const drawerWidth = 310;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: themes.palette.delete.main,
      color: themes.palette.delete.contrastText,
      toolbar: themes.mixins.toolbar,
    },
    rootItem: {
      background: "#E1A731",
      display: "flex",
    },

    drawer: {
      [theme.breakpoints.up("lg")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up("lg")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        position: "relative",
        zIndex: theme.zIndex.drawer + 1,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    hide: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
      display: "none",
    },
    largerProfileCircle: {
      width: "175px",
      height: "175px",
      background: "#F5F5F5",
      margin: "auto",
      marginTop: "18px",
      marginBottom: "38px",
      borderRadius: "100%",
    },
    profileIcon: {
      width: "calc(100% - 65px)",
      height: "120px",
      background: "white",
      margin: "auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "5px",
    },
    adminTitle: {
      color: "#E1A731",
      fontWeight: "bold",
      fontSize: "23px",
      textAlign: "center",
    },
    listItemText: {
      color: "rgba(0, 0, 0, 0.54)",
      "& span": {
        fontSize: "17.5px",
      },
      MuiSvgIcon: {
        root: {
          fontSize: "44px!important",
        },
      },
    },
    nestedListItemText: {
      color: "rgba(0, 0, 0, 0.54)",
      "& span": {
        fontSize: "15px",
      },
      MuiSvgIcon: {
        root: {
          fontSize: "44px!important",
        },
      },
    },
    delete: {},
    linkItem: {
      color: "rgba(0, 0, 0, 0.54)",
      textDecoration: "none",
    },
    images: {
      overflow: "hidden",
      margin: "auto",
    },
    image: {
      width: "160px",
      height: "160px",
      borderRadius: "100%",
    },
    avatarImages: {
      borderRadius: "200px",
      margin: "auto",
      fontSize: "30px",
      backgroundColor: "#67b917",

      [theme.breakpoints.down("sm")]: {
        width: "85px",
        height: "85px",
      },
      [theme.breakpoints.up("sm")]: {
        width: "140px",
        height: "140px",
      },
    },
    toolbarItems: {
      zIndex: 10,
      display: "flex",
      width: "100%",
      position: "fixed",
      top: 0,
      backgroundColor: "white",
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
    },
    mobileSpacing: {
      height: "55px",
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
    },
  })
);

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  windows?: () => Window;
}

const iconObject: any = {
  CustomStatsIcon: (
    <img
      alt="CustomStatsIcon"
      src={CustomStatsIcon}
      style={{
        maxWidth: "29px",
        objectFit: "contain",
        margin: "7px 15px 7px 10px",
      }}
    />
  ),
  CustomManageChamberProfile: (
    <img
      alt="CustomManageChamberProfile"
      src={CustomManageChamberProfile}
      style={{
        maxWidth: "29px",
        objectFit: "contain",
        margin: "7px 15px 7px 10px",
      }}
    />
  ),
  CustomManageCompanyProfile: (
    <img
      alt="CustomManageCompanyProfile"
      src={CustomManageCompanyProfile}
      style={{
        maxWidth: "29px",
        objectFit: "contain",
        margin: "7px 15px 7px 10px",
      }}
    />
  ),
  CustomManageCompanyPerks: (
    <img
      alt="CustomManageCompanyPerks"
      src={CustomManageCompanyPerks}
      style={{
        maxWidth: "29px",
        objectFit: "contain",
        margin: "7px 15px 7px 10px",
      }}
    />
  ),
  CustomManageMembersPerks: (
    <img
      alt="CustomManageMembersPerks"
      src={CustomManageMembersPerks}
      style={{
        maxWidth: "29px",
        objectFit: "contain",
        margin: "7px 15px 7px 10px",
      }}
    />
  ),
  CustomViewMemberPerks: (
    <img
      alt="CustomViewMemberPerks"
      src={CustomViewMemberPerks}
      style={{
        maxWidth: "29px",
        objectFit: "contain",
        margin: "7px 15px 7px 10px",
      }}
    />
  ),
  CustomAddIcon: (
    <img
      alt="CustomAddIcon"
      src={CustomAddIcon}
      style={{
        maxWidth: "29px",
        objectFit: "contain",
        margin: "7px 15px 7px 10px",
      }}
    />
  ),
  CustomSupport: (
    <img
      alt="CustomSupport"
      src={CustomSupport}
      style={{
        maxWidth: "29px",
        objectFit: "contain",
        margin: "7px 15px 7px 10px",
      }}
    />
  ),
  UserGuideAndSupportIcon: (
    <img
      alt="UserGuideAndSupportIcon"
      src={UserGuideAndSupportIcon}
      style={{
        maxWidth: "29px",
        objectFit: "contain",
        margin: "7px 15px 7px 10px",
      }}
    />
  ),
  ShopLocalIcon: (
    <img
      alt="ShopLocalIcon"
      src={ShopLocalIcon}
      style={{
        maxWidth: "29px",
        objectFit: "contain",
        margin: "7px 15px 7px 10px",
      }}
    />
  ),
  RewardIcon: (
    <img
      alt="RewardIcon"
      src={RewardIcon}
      style={{
        maxWidth: "29px",
        objectFit: "contain",
        margin: "7px 15px 7px 10px",
      }}
    />
  ),
  PerkLinkIcon: (
    <img
      alt="PerkLinkIcon"
      src={PerkLinkIcon}
      style={{
        maxWidth: "29px",
        objectFit: "contain",
        margin: "7px 15px 7px 10px",
      }}
    />
  ),
  CPAMarketingToolkit: (
    <img
      alt="CPAMarketingToolkitLogo"
      src={CPAMarketingToolkitLogo}
      style={{
        maxWidth: "29px",
        objectFit: "contain",
        margin: "7px 15px 7px 10px",
      }}
    />
  ),
  HandWithPersonIcon: (
    <img
      alt="HandWithPersonIconLogo"
      src={HandWithPersonIconLogo}
      style={{
        maxWidth: "29px",
        objectFit: "contain",
        margin: "7px 15px 7px 10px",
      }}
    />
  ),
  CustomChamberEngageIcon: (
    <img
      alt="CustomChamberEngageIcon"
      src={CustomChamberEngageIcon}
      style={{
        maxWidth: "212px",
        objectFit: "contain",
        margin: "12px 0px 7px 12px",
      }}
    />
  ),
  CustomChamberMarketerLogo: (
    <img
      alt="CustomChamberMarketerLogo"
      src={CustomChamberMarketerLogo}
      style={{
        maxWidth: "212px",
        objectFit: "contain",
        margin: "12px 0px 7px 12px",
      }}
    />
  ),
  CustomManualLaunchGuideLogo: (
    <img
      alt="CustomManualLaunchGuideLogo"
      src={CustomManualLaunchGuideLogo}
      style={{
        maxWidth: "202px",
        objectFit: "contain",
        margin: "6px 0px 9px 12px",
      }}
    />
  ),
  ChamberEngageManageCategoryAdvertisingBannersChamberMemberIcon: (
    <img
      alt="ChamberEngageManageCategoryAdvertisingBannersChamberMemberIcon"
      src={ChamberEngageManageCategoryAdvertisingBannersChamberMemberIcon}
      style={{
        maxWidth: "29px",
        objectFit: "contain",
        margin: "7px 15px 7px 10px",
      }}
    />
  ),
};

const NestedNavBarButton = ({
  route,
  iconName,
  title,
  useIconAsNavBarButtonTitle,
  nestedRoutes,
  aShopLocalCampaignIsActive,
  aRewardCampaignIsActive,
  setMobileOpen,
}: any) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  return (
    <div>
      <ListItem button onClick={() => setOpen(!open)}>
        <ListItemIcon>{iconObject[iconName]}</ListItemIcon>
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            {!useIconAsNavBarButtonTitle && (
              <ListItemText className={classes.listItemText} primary={title} />
            )}
            <div
              style={{
                marginLeft: "5px",
                color: "rgba(0, 0, 0, 0.46)",
              }}
            >
              {open ? (
                <ArrowUp style={{ fontSize: 30 }} />
              ) : (
                <ArrowDown style={{ fontSize: 30 }} />
              )}
            </div>
          </div>
          {aShopLocalCampaignIsActive &&
            route === RedirectUri.chamberShopLocal && (
              <Chip
                label={"Active"}
                variant="outlined"
                size="small"
                style={{
                  marginBottom: 11,
                  marginTop: -4,
                  color: "#34694F",
                  backgroundColor: "#DCEEDC",
                  borderColor: "#34694F",
                }}
              />
            )}
          {aRewardCampaignIsActive && route === RedirectUri.chamberReward && (
            <Chip
              label={"Active"}
              variant="outlined"
              size="small"
              style={{
                marginBottom: 11,
                marginTop: -4,
                color: "#34694F",
                backgroundColor: "#DCEEDC",
                borderColor: "#34694F",
              }}
            />
          )}
        </div>
      </ListItem>
      {open &&
        nestedRoutes.map((nestedRoute: any, index: number) => {
          if (
            aShopLocalCampaignIsActive &&
            nestedRoute.route === RedirectUri.createShopLocal
          ) {
            return null;
          }

          if (
            !aShopLocalCampaignIsActive &&
            nestedRoute.route === RedirectUri.activeChamberShopLocal
          ) {
            return null;
          }

          if (
            aRewardCampaignIsActive &&
            nestedRoute.route === RedirectUri.createReward
          ) {
            return null;
          }

          if (
            !aRewardCampaignIsActive &&
            nestedRoute.route === RedirectUri.activeChamberReward
          ) {
            return null;
          }

          return (
            <Link
              className={classes.linkItem}
              onClick={() => {
                setMobileOpen(false);

                // only refresh if trying to navigate to route again... user may want to see new content
                if (
                  nestedRoute.refreshOnClick &&
                  (window.location.pathname || "").trim().toLowerCase() ===
                    nestedRoute.route
                ) {
                  window.location.href = nestedRoute.route;
                }
              }}
              to={nestedRoute.route}
              key={index}
            >
              <ListItem button key={index} style={{ paddingLeft: 20 }}>
                <ListItemIcon>{iconObject[nestedRoute.iconName]}</ListItemIcon>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <ListItemText
                    className={classes.nestedListItemText}
                    primary={nestedRoute.title}
                  />
                  {aShopLocalCampaignIsActive &&
                    nestedRoute.route ===
                      RedirectUri.activeChamberShopLocal && (
                      <Chip
                        label={""}
                        variant="outlined"
                        size="small"
                        style={{
                          marginRight: 10,
                          color: "#34694F",
                          backgroundColor: "#DCEEDC",
                          borderColor: "#34694F",
                        }}
                      />
                    )}
                  {aRewardCampaignIsActive &&
                    nestedRoute.route === RedirectUri.activeChamberReward && (
                      <Chip
                        label={""}
                        variant="outlined"
                        size="small"
                        style={{
                          marginRight: 10,
                          color: "#34694F",
                          backgroundColor: "#DCEEDC",
                          borderColor: "#34694F",
                        }}
                      />
                    )}
                </div>
              </ListItem>
            </Link>
          );
        })}
    </div>
  );
};

const handleDivClick = ({ url }: { url: string }) => {
  // Check if the URL starts with 'http://' or 'https://'
  const updatedUrl =
    url.startsWith("http://") || url.startsWith("https://")
      ? url
      : `http://${url}`;
  window.open(updatedUrl, "_blank");
};

export default function ResponsiveDrawer(props: Props) {
  const { windows } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const {
    state: { userType },
  } = useContext(UserTypeContext);
  const chamberInfo = useGetChamberFromUserIdInAuth();
  const chamberMemberInfo = useGetChamberMemberFromUserIdInAuth();

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen(!mobileOpen);
  }, [mobileOpen]);

  const handleLogout = useHandleLogout();

  const { data: activeShopLocalCampaignForChamberMemberData } =
    useGetActiveShopLocalCampaignForChamberMemberQuery({
      skip: userType !== UserType.ChamberMemberAdmin,
      fetchPolicy: "network-only",
    });

  const { data: allShopLocalCampaignsForChamber } =
    useGetAllShopLocalCampaignsForChamberQuery({
      fetchPolicy: "network-only",
      skip: userType !== UserType.ChamberAdmin,
    });

  const aShopLocalCampaignIsActive = useMemo(() => {
    if (userType === UserType.ChamberMemberAdmin) {
      if (
        activeShopLocalCampaignForChamberMemberData?.getActiveShopLocalCampaignForChamber
      ) {
        return {
          isUpcoming: !(
            Date.now() >
            (activeShopLocalCampaignForChamberMemberData
              ?.getActiveShopLocalCampaignForChamber?.startDateUtcMilli || 0)
          ),
        };
      }
    }

    if (userType === UserType.ChamberAdmin) {
      for (const campaign of allShopLocalCampaignsForChamber?.getAllShopLocalCampaignsForChamber ||
        []) {
        if (campaign?.status === ShopLocalCampaignStatusEnum.Active) {
          return {
            isUpcoming: !(Date.now() > campaign?.startDateUtcMilli),
          };
        }
      }
    }

    return null;
  }, [
    activeShopLocalCampaignForChamberMemberData?.getActiveShopLocalCampaignForChamber,
    allShopLocalCampaignsForChamber?.getAllShopLocalCampaignsForChamber,
    userType,
  ]);

  const { data: allRewardCampaignsForChamber } =
    useGetAllRewardCampaignsForChamberQuery({
      fetchPolicy: "network-only",
      skip: userType !== UserType.ChamberAdmin,
    });

  const aRewardCampaignIsActive = useMemo(() => {
    if (userType === UserType.ChamberAdmin) {
      for (const campaign of allRewardCampaignsForChamber?.getAllRewardCampaignsForChamber ||
        []) {
        const { campaignIsActive, campaignIsUpcoming } =
          checkIfRewardCampaignIsActive({
            campaign,
          });

        if (campaignIsActive || campaignIsUpcoming) {
          return {
            isUpcoming: campaignIsUpcoming,
          };
        }
      }
    }

    return null;
  }, [allRewardCampaignsForChamber?.getAllRewardCampaignsForChamber, userType]);

  const isMemberProfileAndPerksBannersActiveForChamberMember =
    useIsMemberProfileAndPerksBannersActiveForChamberMember({
      activeMemberProfileAndPerksBannersSettings:
        chamberMemberInfo?.activeMemberProfileAndPerksBannersSettings,
    });

  const navToShow = useMemo(() => {
    if (!userType || userType.length === 0) {
      return [];
    }
    // @ts-ignore
    const routeObjs = Object.values(enumStrings.groups[userType as UserType]);
    return routeObjs.map(
      (
        {
          route,
          iconName,
          title,
          showInNavBar,
          sandboxTitle,
          sandBoxIconName,
          onlyShowOnSandbox,
          nestedRoutes,
          useIconAsNavBarButtonTitle,
          onlyShowOnActiveChamberEngage,
          showEnhancedBannersPill,
          refreshOnClick,
        }: any,
        index
      ) => {
        if (onlyShowOnSandbox && !chamberInfo?.isSandbox) {
          return null;
        }

        if (
          onlyShowOnActiveChamberEngage &&
          !chamberInfo?.chamberEngageSettings.isActive
        ) {
          return null;
        }

        return (
          <React.Fragment key={index}>
            {showInNavBar ? (
              <>
                {nestedRoutes ? (
                  <NestedNavBarButton
                    route={route}
                    iconName={iconName}
                    title={title}
                    useIconAsNavBarButtonTitle={useIconAsNavBarButtonTitle}
                    nestedRoutes={nestedRoutes}
                    setMobileOpen={setMobileOpen}
                    aShopLocalCampaignIsActive={aShopLocalCampaignIsActive}
                    aRewardCampaignIsActive={aRewardCampaignIsActive}
                  />
                ) : (
                  <Link
                    className={classes.linkItem}
                    onClick={() => {
                      setMobileOpen(false);

                      // only refresh if trying to navigate to route again... user may want to see new content
                      if (
                        refreshOnClick &&
                        (window.location.pathname || "")
                          .trim()
                          .toLowerCase() === route
                      ) {
                        window.location.href = route;
                      }
                    }}
                    to={route}
                  >
                    <ListItem button key={index}>
                      <ListItemIcon>
                        {
                          iconObject[
                            chamberInfo?.isSandbox && sandBoxIconName
                              ? sandBoxIconName
                              : iconName
                          ]
                        }
                      </ListItemIcon>
                      {!useIconAsNavBarButtonTitle && (
                        <div>
                          <ListItemText
                            className={classes.listItemText}
                            primary={
                              chamberInfo?.isSandbox && sandboxTitle
                                ? sandboxTitle
                                : title
                            }
                          />
                          {aShopLocalCampaignIsActive &&
                            route === RedirectUri.chamberMemberShopLocal && (
                              <Chip
                                label={"Active"}
                                variant="outlined"
                                size="small"
                                style={{
                                  marginBottom: 11,
                                  marginTop: -4,
                                  color: "#34694F",
                                  backgroundColor: "#DCEEDC",
                                  borderColor: "#34694F",
                                }}
                              />
                            )}
                          {showEnhancedBannersPill && (
                            <Tooltip
                              arrow
                              title={
                                <div
                                  style={{
                                    backgroundColor: "#707070",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      color: "white",
                                      fontSize: "12px",
                                      whiteSpace: "pre-wrap",
                                    }}
                                  >
                                    {isMemberProfileAndPerksBannersActiveForChamberMember
                                      ? `Looks like your banners are active! Start adding your banner to elevate your company's appearance and attract more business. Contact ${chamberInfo?.name} to learn more!`
                                      : `Looks like your banners are inactive. Contact ${chamberInfo?.name} to learn more!`}
                                  </Typography>
                                </div>
                              }
                              placement="right"
                            >
                              <Chip
                                label={
                                  isMemberProfileAndPerksBannersActiveForChamberMember
                                    ? "Enhanced Banners Active"
                                    : "Enhanced Banners Inactive"
                                }
                                variant="outlined"
                                size="small"
                                style={{
                                  marginBottom: 11,
                                  marginTop: -4,
                                  backgroundColor:
                                    isMemberProfileAndPerksBannersActiveForChamberMember
                                      ? "#E2ECDE"
                                      : "#E3E4E6",
                                  borderColor:
                                    isMemberProfileAndPerksBannersActiveForChamberMember
                                      ? "#4F6C5A"
                                      : "#727272",
                                  color:
                                    isMemberProfileAndPerksBannersActiveForChamberMember
                                      ? "#4F6C5A"
                                      : "#727272",
                                }}
                              />
                            </Tooltip>
                          )}
                        </div>
                      )}
                    </ListItem>
                  </Link>
                )}
              </>
            ) : null}
          </React.Fragment>
        );
      }
    );
  }, [
    aRewardCampaignIsActive,
    aShopLocalCampaignIsActive,
    chamberInfo?.chamberEngageSettings.isActive,
    chamberInfo?.isSandbox,
    chamberInfo?.name,
    classes.linkItem,
    classes.listItemText,
    isMemberProfileAndPerksBannersActiveForChamberMember,
    userType,
  ]);

  const logoSource = useMemo(() => {
    if (
      userType === UserType.RootAdmin ||
      userType === UserType.PerkPartnerDiscountAdmin
    ) {
      return CPALogo;
    }

    if (userType === UserType.ChamberMemberAdmin) {
      return chamberMemberInfo?.logo;
    }

    return chamberInfo?.logo;
  }, [chamberInfo?.logo, chamberMemberInfo?.logo, userType]);

  const isChamber = useIsChamber();

  const getNavTitle = useCallback(() => {
    if (userType === UserType.RootAdmin) {
      return enumStrings.navText.rootAdminText;
    }
    if (userType === UserType.ChamberAdmin) {
      return isChamber
        ? enumStrings.navText.chamberAdminText
        : enumStrings.navText.boardAdminText;
    }
    if (userType === UserType.ChamberMemberAdmin) {
      return enumStrings.navText.chamberMemberAdminText;
    }
    if (userType === UserType.PerkPartnerDiscountAdmin) {
      return enumStrings.navText.perkPartnerAdminText;
    }

    return "Dashboard";
  }, [isChamber, userType]);

  const drawer = (
    <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
      <div className={classes.toolbar}>
        <div
          style={{
            fontWeight: "bold",
            textAlign: "center",
            paddingTop: "20px",
          }}
        >
          {userType !== UserType.RootAdmin && userType !== UserType.ChamberAdmin
            ? chamberInfo?.name
            : ""}
        </div>
        <div>
          <div
            style={{
              textAlign: "center",
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
          >
            <span className={classes.adminTitle}>{getNavTitle()}</span>
          </div>
          <div className={classes.profileIcon}>
            {/* @ts-ignore */}
            <LogoImage
              dontLazyLoad
              maxWidth
              squared
              size="xl"
              logoSource={logoSource}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          flex: 1,
        }}
      >
        <div>
          <List style={{ paddingTop: 0 }}>{navToShow}</List>
        </div>
        <div>
          {userType !== UserType.RootAdmin &&
          userType !== UserType.PerkPartnerDiscountAdmin ? (
            <>
              <div
                style={{
                  paddingBottom: "0px",
                  color: "rgba(0, 0, 0, 0.54)",
                  fontSize: "17.5px",
                  textAlign: "center",
                }}
              >
                Sponsored By
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  borderRadius: "16px",
                  margin: "15px",
                  marginTop: "5px",
                  marginBottom: "0px",
                  boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
                  padding: "10px",
                  fontWeight: "bold",
                }}
              >
                <div
                  style={{
                    paddingRight: "15px",
                    paddingLeft: "15px",
                  }}
                >
                  {chamberInfo?.sponsorLogo ? (
                    <LogoImage
                      dontLazyLoad
                      onClick={
                        chamberInfo?.sponsorLogoLink &&
                        chamberInfo?.sponsorLogoLink.length > 0
                          ? () =>
                              handleDivClick({
                                url: chamberInfo?.sponsorLogoLink || "",
                              })
                          : undefined
                      }
                      maxWidth
                      squared
                      size="normal"
                      logoSource={chamberInfo?.sponsorLogo || ""}
                    />
                  ) : (
                    <div
                      style={{
                        color: "rgba(0, 0, 0, 0.54)",
                        fontSize: "20px",
                        height: 70,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Available
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : null}
          <ListItemButton
            style={{
              padding: 0,
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <ListItem onClick={handleLogout}>
              <ListItemIcon>
                <img
                  alt="CustomLogoutIcon"
                  src={CustomLogoutIcon}
                  style={{
                    maxWidth: "29px",
                    objectFit: "contain",
                    margin: "7px 15px 7px 10px",
                  }}
                />
              </ListItemIcon>
              <ListItemText
                className={classes.listItemText}
                primary={"Logout"}
              />
            </ListItem>
          </ListItemButton>
        </div>
      </div>
    </div>
  );

  const container =
    windows !== undefined ? () => windows().document.body : undefined;

  return (
    <div className={classes.rootItem}>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <CssBaseline />
        <Toolbar className={classes.toolbarItems}>
          <div
            style={{
              flex: 1,
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={clsx(classes.menuButton, mobileOpen && classes.hide)}
              style={{
                backgroundColor: "#E1A731",
                width: "50px",
                height: "50px",
                color: "white",
              }}
            >
              <MenuIcon />
            </IconButton>
          </div>
          <div
            style={{
              textAlign: "center",
              color: "#E1A731",
              fontWeight: "bold",
              fontSize: "18px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              flex: 1,
            }}
          >
            {getNavTitle()}
          </div>
          <div style={{ flex: 1 }} />
        </Toolbar>
        <div className={classes.mobileSpacing} />
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
}
