import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import {
  GetAllChamberMembersQuery,
  ShopLocalCampaignPrizeDrawParticipantsReturn,
  ShopLocalCampaignReturn,
  ShopLocalCampaignStatusEnum,
} from "../../../graphql/types";
import { getLocalTimestamp } from "../Root/RootAdmin";
import { Button, Chip, makeStyles, Modal, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { RedirectUri } from "../../enumStrings";
import theme from "../../../themes";
import { EndCampaignModal } from "./updateChamberShopLocalCampaign";

type OmittedRedeemSettingsShopLocalCampaign = ShopLocalCampaignReturn;
type AllShopLocalCampaignDataType =
  | ShopLocalCampaignReturn[]
  | undefined
  | null;
type TableShopLocalCampaignDataType = OmittedRedeemSettingsShopLocalCampaign;
type AllTableShopLocalCampaignDataType = TableShopLocalCampaignDataType[];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(
  array: AllTableShopLocalCampaignDataType,
  comparator: (a: any, b: any) => number
): AllTableShopLocalCampaignDataType {
  const stabilizedThis = array.map((el, index) => [el, index] as [any, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  id: string;
  label: string;
  alignCenter?: boolean;
  preventTextWrap?: boolean;
}

interface FullPageShopLocalCampaignTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  headCells: HeadCell[];
}

function FullPageShopLocalCampaignTableHead(
  props: FullPageShopLocalCampaignTableProps
) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              whiteSpace: headCell.preventTextWrap ? "nowrap" : undefined,
            }}
            key={headCell.id}
            align={headCell.alignCenter ? "center" : "left"}
            padding={"normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box
                  component="span"
                  // @ts-ignore
                  sx={visuallyHidden}
                >
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const headCells: HeadCell[] = [
  {
    id: "campaignName",
    label: "Contest Name",
  },
  {
    id: "prizeDescription",
    label: "Prize Description",
  },
  {
    id: "startDateUtcMilli",
    label: "Start Date",
    preventTextWrap: true,
  },
  {
    id: "endDateUtcMilli",
    label: "End Date",
    preventTextWrap: true,
  },
  {
    id: "participatingMembers",
    label: "Participating Member Companies",
  },
  {
    id: "prizeDrawParticipants",
    label: "Chamber Perks App™ Users in Prize Draw",
  },
  {
    id: "status",
    label: "Contest Status",
  },
  {
    id: "updateActiveCampaign",
    label: "",
  },
];

const useStyles = makeStyles(() => ({
  modal: {
    border: "unset",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    height: "700px",
    borderRadius: "5px",
    textAlign: "center",
    width: "740px",
    maxWidth: "90%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "#cfd8dc",
    padding: theme.spacing(4),
    position: "relative",
  },
  modalButton: {
    margin: "10px",
    marginTop: "20px",
    width: "230px",
    backgroundColor: "#e1a731",
    color: "#e9e9e9",
    fontWeight: "bold",
    height: "40px",
  },
}));

export default function FullPageShopLocalCampaignsTable({
  allShopLocalCampaignData,
  allChamberMemberData,
  mode,
}: {
  allChamberMemberData: GetAllChamberMembersQuery | undefined;
  allShopLocalCampaignData: AllShopLocalCampaignDataType;
  mode: "active" | "inactive";
}) {
  const classes = useStyles();
  const history = useHistory();

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<string>("chamberMemberName");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  React.useEffect(() => {
    setOrder("desc");
    setOrderBy("startDateUtcMilli");
  }, [allShopLocalCampaignData]);

  const allTableShopLocalCampaignData: AllTableShopLocalCampaignDataType =
    React.useMemo(() => {
      if (!allShopLocalCampaignData) {
        return [];
      }

      const allShopLocalCampaignsArr = (allShopLocalCampaignData || []).map(
        (shopLocalCampaignData) => ({
          ...shopLocalCampaignData,
        })
      );

      if (mode === "active") {
        return allShopLocalCampaignsArr.filter(
          (campaign) => campaign.status === ShopLocalCampaignStatusEnum.Active
        );
      }

      return allShopLocalCampaignsArr.filter(
        (campaign) => campaign.status === ShopLocalCampaignStatusEnum.Inactive
      );
    }, [allShopLocalCampaignData, mode]);

  const visibleRows = React.useMemo(
    () =>
      stableSort(allTableShopLocalCampaignData, getComparator(order, orderBy)),
    [allTableShopLocalCampaignData, order, orderBy]
  );

  const [memberModalOpenedForCampaignsId, setMemberModalOpenedForCampaignsId] =
    React.useState<string>("");

  const [memberModalOpenedIsActive, setMemberModalOpenedIsActive] =
    React.useState<boolean>(false);

  const [participatingMembersModalOpen, setParticipatingMembersModalOpen] =
    React.useState<boolean>(false);

  const [participantModalOpen, setParticipantModalOpen] =
    React.useState<boolean>(false);

  const [randomWinnerModalOpen, setRandomWinnerModalOpen] =
    React.useState<boolean>(false);
  const [randomWinnerEmail, setRandomWinnerEmail] = React.useState<string>("");

  const participatingMemberCompaniesList = React.useMemo(() => {
    if (
      memberModalOpenedForCampaignsId.length > 0 &&
      (allShopLocalCampaignData || []).length > 0 &&
      (allChamberMemberData?.getAllChamberMembers || []).length > 0
    ) {
      // create object for member id to number of participants
      const participatingMembersMap: Record<string, number> = {};
      for (const campaign of allShopLocalCampaignData || []) {
        if (campaign.id === memberModalOpenedForCampaignsId) {
          for (const participantObj of campaign.prizeDrawParticipants || []) {
            for (const memberId of participantObj.redeemedAtMemberIds || []) {
              if (participatingMembersMap[memberId]) {
                participatingMembersMap[memberId] += 1;
              } else {
                participatingMembersMap[memberId] = 1;
              }
            }
          }
          break;
        }
      }

      const listOfParticipatingMembers: {
        memberName: string;
        numberOfParticipants: number;
        participationCode: string;
      }[] = [];

      for (const campaign of allShopLocalCampaignData || []) {
        if (campaign.id === memberModalOpenedForCampaignsId) {
          for (const participatingMemebrObj of campaign.participatingMembers) {
            for (const realMember of allChamberMemberData?.getAllChamberMembers ||
              []) {
              if (participatingMemebrObj.memberId === realMember.id) {
                listOfParticipatingMembers.push({
                  memberName: realMember.name,
                  numberOfParticipants:
                    participatingMembersMap[realMember.id] || 0,
                  participationCode: participatingMemebrObj.participationCode,
                });
                continue;
              }
            }
          }
          break;
        }
      }

      // 1. Filter and sort members with numberOfParticipants > 0 alphabetically
      const positiveParticipants = listOfParticipatingMembers
        .filter((member) => member.numberOfParticipants > 0)
        .sort((a, b) => a.memberName.localeCompare(b.memberName));

      // 2. Filter and sort members with numberOfParticipants === 0 alphabetically
      const zeroParticipants = listOfParticipatingMembers
        .filter((member) => member.numberOfParticipants === 0)
        .sort((a, b) => a.memberName.localeCompare(b.memberName));

      // 3. Join the two lists
      const sortedMembers = [...positiveParticipants, ...zeroParticipants];

      return (
        <div>
          {sortedMembers.map((member) => (
            <div key={member.memberName} style={{ paddingBottom: 10 }}>
              <div>
                <ul>
                  <li>{member.memberName}</li>
                  <ul>
                    <li>
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        Participation code:
                      </span>{" "}
                      {member.participationCode}
                    </li>
                    <li>
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        Users in prize draw:
                      </span>{" "}
                      {member.numberOfParticipants}
                    </li>
                  </ul>
                </ul>
              </div>
            </div>
          ))}
        </div>
      );
    }
    return null;
  }, [
    allChamberMemberData?.getAllChamberMembers,
    allShopLocalCampaignData,
    memberModalOpenedForCampaignsId,
  ]);

  const cpaUsersParticipatingInDrawList = React.useMemo(() => {
    if (
      memberModalOpenedForCampaignsId.length > 0 &&
      (allShopLocalCampaignData || []).length > 0 &&
      (allChamberMemberData?.getAllChamberMembers || []).length > 0
    ) {
      let prizeDrawParticipantArr: ShopLocalCampaignPrizeDrawParticipantsReturn[] =
        [];
      for (const campaign of allShopLocalCampaignData || []) {
        if (campaign.id === memberModalOpenedForCampaignsId) {
          prizeDrawParticipantArr = campaign.prizeDrawParticipants || [];
          break;
        }
      }
      if (prizeDrawParticipantArr.length > 0) {
        prizeDrawParticipantArr = prizeDrawParticipantArr
          .slice()
          .sort(
            (a, b) =>
              b.redeemedAtMemberIds.length - a.redeemedAtMemberIds.length
          );
      }

      let memberIdsToPullNamesFor: string[] = [];

      for (const participantObj of prizeDrawParticipantArr) {
        for (const memberId of participantObj.redeemedAtMemberIds || []) {
          memberIdsToPullNamesFor.push(memberId);
        }
      }

      // @ts-ignore
      memberIdsToPullNamesFor = [...new Set(memberIdsToPullNamesFor)];

      const memberIdToName: {
        [key: string]: string;
      } = {};
      for (const id of memberIdsToPullNamesFor) {
        for (const member of allChamberMemberData?.getAllChamberMembers || []) {
          if (member.id === id) {
            memberIdToName[id] = member.name;
          }
        }
      }

      const listOfParticipatingCPAUsers = [];

      for (const participantObj of prizeDrawParticipantArr) {
        listOfParticipatingCPAUsers.push(
          <div key={participantObj.userEmail} style={{ paddingTop: 10 }}>
            <span style={{ fontWeight: "bold" }}>Entrant email:</span>{" "}
            {participantObj.userEmail} <br />
            <span style={{ fontWeight: "bold" }}>
              Entered prize draw by redeeming perks from:
            </span>{" "}
            <ol>
              {participantObj.redeemedAtMemberIds.map((memberId) => (
                <li key={memberId}>{memberIdToName[memberId]}</li>
              ))}
            </ol>
          </div>
        );
      }

      return <div>{listOfParticipatingCPAUsers}</div>;
    }

    return <div>None so far</div>;
  }, [
    allChamberMemberData?.getAllChamberMembers,
    allShopLocalCampaignData,
    memberModalOpenedForCampaignsId,
  ]);

  const setRandomWinnerEmailFunc = React.useCallback(() => {
    if (
      memberModalOpenedForCampaignsId.length > 0 &&
      (allShopLocalCampaignData || []).length > 0 &&
      (allChamberMemberData?.getAllChamberMembers || []).length > 0
    ) {
      let prizeDrawParticipantArr: ShopLocalCampaignPrizeDrawParticipantsReturn[] =
        [];
      for (const campaign of allShopLocalCampaignData || []) {
        if (campaign.id === memberModalOpenedForCampaignsId) {
          prizeDrawParticipantArr = campaign.prizeDrawParticipants || [];
          break;
        }
      }

      if (prizeDrawParticipantArr.length > 0) {
        // create weighted array to favour entrants with higher number of member perks redeemed
        const weightedArray:
          | { userEmail: string; redeemedAtMemberIds: string[] }[] = [];

        for (const entry of prizeDrawParticipantArr) {
          for (let i = 0; i < (entry.redeemedAtMemberIds || []).length; i++) {
            weightedArray.push({
              userEmail: entry.userEmail,
              redeemedAtMemberIds: entry.redeemedAtMemberIds,
            });
          }
        }

        // set random winner emial
        const randomWinner =
          weightedArray[Math.floor(Math.random() * weightedArray.length)];
        setRandomWinnerEmail(
          `${randomWinner.userEmail}\n\n(Redeemed perks from ${randomWinner.redeemedAtMemberIds.length} participating member companies to enter prize draw)`
        );
      } else {
        setRandomWinnerEmail("");
      }
    }
  }, [
    allChamberMemberData?.getAllChamberMembers,
    allShopLocalCampaignData,
    memberModalOpenedForCampaignsId,
  ]);

  const [endCampaignModalOpen, setEndCampaignModalOpen] = React.useState(false);
  const [endModalCampaignId, setEndModalCampaignId] = React.useState("");

  if ((allTableShopLocalCampaignData || []).length === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Typography
          style={{
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          {mode === "active"
            ? "No active contest found"
            : "No previous contests found"}
        </Typography>
      </div>
    );
  }

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      {endCampaignModalOpen && (
        <EndCampaignModal
          campaignId={endModalCampaignId}
          closeModal={() => setEndCampaignModalOpen(false)}
        />
      )}
      {participatingMembersModalOpen && (
        <Modal className={classes.modal} open={participatingMembersModalOpen}>
          <div
            className={classes.paper}
            style={{ justifyContent: "space-between" }}
          >
            <h1 style={{ fontWeight: "bold" }}>
              Participating Member Companies
            </h1>
            <h3 style={{ paddingLeft: 10, paddingRight: 10 }}>
              Prize draw participation code and Number of Chamber Perks App™
              users who have entered the prize draw by redeeming a perk from a
              participating company are shown under each company name.
            </h3>
            <div
              style={{
                padding: "50px",
                paddingBottom: 0,
                paddingTop: 20,
                textAlign: "left",
                fontSize: "16px",
                height: "100%",
                overflowY: "scroll",
              }}
            >
              {participatingMemberCompaniesList}
            </div>
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              {memberModalOpenedIsActive && (
                <Button
                  className={classes.modalButton}
                  onClick={() =>
                    history.push(RedirectUri.updateShopLocal, {
                      shopLocalCampaignId: memberModalOpenedForCampaignsId,
                    })
                  }
                  variant="contained"
                >
                  Update Member List
                </Button>
              )}
              <Button
                className={classes.modalButton}
                style={{
                  backgroundColor: "#37474f",
                  color: "#e9e9e9",
                }}
                onClick={() => setParticipatingMembersModalOpen(false)}
                variant="contained"
              >
                Close
              </Button>
            </div>
          </div>
        </Modal>
      )}

      {participantModalOpen && (
        <Modal className={classes.modal} open={participantModalOpen}>
          <div
            className={classes.paper}
            style={{ justifyContent: "space-between" }}
          >
            <h1 style={{ fontWeight: "bold" }}>
              Chamber Perks App™ Users
              <br />
              Participating in Prize Draw
            </h1>
            <div
              style={{
                padding: "50px",
                paddingBottom: 0,
                paddingTop: 20,
                textAlign: "left",
                fontSize: "16px",
                height: "100%",
                overflowY: "scroll",
              }}
            >
              {cpaUsersParticipatingInDrawList}
            </div>
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <Button
                className={classes.modalButton}
                onClick={() => {
                  setRandomWinnerEmailFunc();
                  setRandomWinnerModalOpen(true);
                }}
                variant="contained"
              >
                Pick Random Winner
              </Button>
              <Button
                className={classes.modalButton}
                style={{
                  backgroundColor: "#37474f",
                  color: "#e9e9e9",
                }}
                onClick={() => setParticipantModalOpen(false)}
                variant="contained"
              >
                Close
              </Button>
            </div>
          </div>
        </Modal>
      )}

      {randomWinnerModalOpen && (
        <Modal className={classes.modal} open={randomWinnerModalOpen}>
          <div
            className={classes.paper}
            style={{ justifyContent: "space-between", height: "300px" }}
          >
            <h1 style={{ fontWeight: "bold" }}>Random Winner Is</h1>
            <div
              style={{
                padding: "50px",
                paddingBottom: 0,
                paddingTop: 20,
                textAlign: "center",
                fontSize: "16px",
                height: "100%",
                overflowY: "scroll",
                whiteSpace: "pre-wrap",
              }}
            >
              {randomWinnerEmail}
            </div>
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <Button
                className={classes.modalButton}
                style={{
                  backgroundColor: "#37474f",
                  color: "#e9e9e9",
                }}
                onClick={() => setRandomWinnerModalOpen(false)}
                variant="contained"
              >
                Close
              </Button>
            </div>
          </div>
        </Modal>
      )}

      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          borderRadius: "16px",
          boxShadow: "unset",
        }}
      >
        <TableContainer sx={{ maxHeight: "80vh" }}>
          <Table
            sx={{
              minWidth: 750,
              maxWidth: 1300,
              margin: "auto",
            }}
            aria-labelledby="tableTitle"
            size={"medium"}
            stickyHeader
          >
            <FullPageShopLocalCampaignTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />
            <TableBody>
              {visibleRows.map((shopLocalCampaignInfo) => {
                return (
                  <TableRow
                    hover
                    style={{
                      cursor: "unset",
                    }}
                    onClick={undefined}
                    role="checkbox"
                    tabIndex={-1}
                    key={shopLocalCampaignInfo.id}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell
                      style={{ fontSize: "16px" }}
                      component="th"
                      scope="row"
                    >
                      {shopLocalCampaignInfo.campaignName}
                    </TableCell>
                    <TableCell
                      style={{ fontSize: "16px" }}
                      component="th"
                      scope="row"
                    >
                      {shopLocalCampaignInfo.prizeDescription}
                    </TableCell>
                    <TableCell
                      style={{ fontSize: "16px" }}
                      component="th"
                      scope="row"
                    >
                      {getLocalTimestamp(
                        shopLocalCampaignInfo.startDateUtcMilli || 0,
                        true
                      )}
                    </TableCell>
                    <TableCell
                      style={{ fontSize: "16px" }}
                      component="th"
                      scope="row"
                    >
                      {getLocalTimestamp(
                        shopLocalCampaignInfo.endDateUtcMilli || 0,
                        true
                      )}
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "16px",
                        textAlign: "center",
                      }}
                      component="th"
                      scope="row"
                    >
                      {shopLocalCampaignInfo.participatingMembers.length}
                      <br />
                      <br />
                      {shopLocalCampaignInfo.participatingMembers.length >
                        0 && (
                        <Button
                          className={classes.modalButton}
                          style={{
                            backgroundColor: "#37474f",
                            color: "#e9e9e9",
                            width: "100px",
                            height: "30px",
                            fontSize: "11.5px",
                            margin: 0,
                            padding: 0,
                          }}
                          onClick={() => {
                            setParticipatingMembersModalOpen(true);
                            setMemberModalOpenedIsActive(
                              shopLocalCampaignInfo.status ===
                                ShopLocalCampaignStatusEnum.Active
                            );
                            setMemberModalOpenedForCampaignsId(
                              shopLocalCampaignInfo.id
                            );
                          }}
                          variant="contained"
                        >
                          Open List
                        </Button>
                      )}
                    </TableCell>
                    <TableCell
                      style={{ fontSize: "16px", textAlign: "center" }}
                      component="th"
                      scope="row"
                    >
                      {
                        (shopLocalCampaignInfo?.prizeDrawParticipants || [])
                          .length
                      }
                      <br />
                      <br />
                      {(shopLocalCampaignInfo?.prizeDrawParticipants || [])
                        .length > 0 && (
                        <Button
                          className={classes.modalButton}
                          style={{
                            backgroundColor: "#37474f",
                            color: "#e9e9e9",
                            width: "120px",
                            height: "50px",
                            fontSize: "11.5px",
                            margin: 0,
                            padding: 0,
                          }}
                          onClick={() => {
                            setMemberModalOpenedForCampaignsId(
                              shopLocalCampaignInfo.id
                            );
                            setParticipantModalOpen(true);
                          }}
                          variant="contained"
                        >
                          Open List And Pick Winner
                        </Button>
                      )}
                    </TableCell>
                    <TableCell
                      style={{ fontSize: "16px", textAlign: "center" }}
                      component="th"
                      scope="row"
                    >
                      {shopLocalCampaignInfo.status ===
                      ShopLocalCampaignStatusEnum.Active ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Chip
                            label={
                              Date.now() <
                              shopLocalCampaignInfo.startDateUtcMilli
                                ? "Upcoming"
                                : "Active"
                            }
                            variant="outlined"
                            size="medium"
                            style={{
                              color:
                                Date.now() <
                                shopLocalCampaignInfo.startDateUtcMilli
                                  ? "#525100"
                                  : "#34694F",

                              backgroundColor:
                                Date.now() <
                                shopLocalCampaignInfo.startDateUtcMilli
                                  ? "#FEF9E8"
                                  : "#DCEEDC",
                              borderColor:
                                Date.now() <
                                shopLocalCampaignInfo.startDateUtcMilli
                                  ? "#525100"
                                  : "#34694F",
                            }}
                          />
                          <Button
                            className={classes.modalButton}
                            style={{
                              backgroundColor: "#37474f",
                              color: "#e9e9e9",
                              width: "70px",
                              height: "30px",
                              fontSize: "11.5px",
                              margin: 0,
                              padding: 0,
                              marginTop: 10,
                            }}
                            onClick={() => {
                              setEndCampaignModalOpen(() => {
                                setEndModalCampaignId(shopLocalCampaignInfo.id);
                                return true;
                              });
                            }}
                            variant="contained"
                          >
                            End
                          </Button>
                        </div>
                      ) : (
                        <Chip
                          label="Ended"
                          variant="outlined"
                          size="medium"
                          style={{
                            color: "#727272",
                            backgroundColor: "#E3E4E6",
                            borderColor: "#727272",
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell
                      style={{ fontSize: "16px" }}
                      component="th"
                      scope="row"
                    >
                      {shopLocalCampaignInfo.status ===
                      ShopLocalCampaignStatusEnum.Active ? (
                        <Button
                          type="submit"
                          size="large"
                          color="primary"
                          variant="contained"
                          style={{
                            backgroundColor: "#E1A731",
                            fontSize: "15px",
                            fontWeight: "bold",
                            borderRadius: "10px",
                            width: "90px",
                            height: "40px",
                          }}
                          onClick={() => {
                            history.push(RedirectUri.updateShopLocal, {
                              shopLocalCampaignId: shopLocalCampaignInfo.id,
                            });
                          }}
                        >
                          Edit
                        </Button>
                      ) : (
                        ""
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
